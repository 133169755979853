import React, { useState, useEffect } from 'react';
import { Button, TextField, Typography, Box } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useNavigate, useLocation } from 'react-router-dom';
import { OnboardingLayout } from './onboard-layout';
import PinkGradientButton from '../../UIControls/PinkGradientButton';
import { inputStyle } from './inputStyle';
import UserApi from '../../../rest-api/user-api';

const OnboardNameScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isShowOffer = searchParams.get('LVS') === 'true';
  const [userData, setUserData] = useState({ ...location.state?.userData });
  const [inviteData, setInviteData] = useState(null);

  useEffect(() => {
    const fetchInviteData = async () => {
      try {
        const queryParams = new URLSearchParams(location.search);
        const inviteId = queryParams.get('inviteId');
        const senderId = queryParams.get('senderId');

        if (inviteId && senderId) {
          const inviteData = await UserApi.getClinicianDataMultiuserNoAuth(inviteId);
          inviteData.inviteId = inviteId;
          inviteData.senderId = senderId;

          // If invite data contains invitedEmail, use it in userData
          if (inviteData.invitedEmail) {
            setUserData((prevUserData) => ({
              ...prevUserData,
              emailAddress: inviteData.invitedEmail,
            }));
          }

          setInviteData(inviteData);
          console.warn("Fetched invite data:", inviteData);
        }
      } catch (error) {
        console.error("Error fetching invite data:", error);
      }
    };

    fetchInviteData();
  }, [location.search]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUserData((userData) => ({ ...userData, [name]: value }));
  };

  const handleNext = () => {
    const { accountType } = userData.subscriptionData || {};
    const nextPathBase = inviteData ? `/onboard/password` : (accountType === 'Student' ? '/onboard/password' : '/onboard/clinic-name');
    const nextPath = inviteData
      ? `${nextPathBase}?inviteId=${inviteData.inviteId}&senderId=${inviteData.senderId}${isShowOffer ? '&LVS=true' : ''}`
      : isShowOffer
      ? `${nextPathBase}?LVS=true`
      : nextPathBase;
      
    navigate(nextPath, { state: { userData } });
  };

  const handleBack = () => {
    const backPath = inviteData
      ? `/onboard/login?inviteId=${inviteData.inviteId}&senderId=${inviteData.senderId}${isShowOffer ? '&LVS=true' : ''}`
      : `/onboard/type${isShowOffer ? '?LVS=true' : ''}`;
    
    navigate(backPath, { state: { userData: { ...userData, firstName: '', lastName: '' } } });
  };

  const isEmailValid = userData.emailAddress && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(userData.emailAddress);
  const isMobileValid = userData.mobile && /^\d+$/.test(userData.mobile); 
  const isNextButtonDisabled = userData.firstName === '' || userData.lastName === '' || !isEmailValid;

  const inputChildren = (
    <>
      <Typography variant="h6">What's your first name?</Typography>
      <TextField
        name="firstName"
        value={userData.firstName}
        onChange={handleInputChange}
        placeholder="Enter your first name"
        fullWidth
        margin="normal"
        sx={inputStyle}
      />
      <Typography variant="h6" marginTop={2}>
        What's your last name?
      </Typography>
      <TextField
        name="lastName"
        value={userData.lastName}
        onChange={handleInputChange}
        placeholder="Enter your last name"
        fullWidth
        margin="normal"
        sx={inputStyle}
      />
      <Typography variant="h6" marginTop={2}>
        What's your email?
      </Typography>
      <TextField
        name="emailAddress"
        type="email"
        value={userData.emailAddress}
        onChange={handleInputChange}
        error={!isEmailValid && userData?.emailAddress?.length > 0}
        helperText={!isEmailValid && userData?.emailAddress?.length > 0 ? 'Invalid email address' : ''}
        required
        fullWidth
        margin="normal"
        placeholder="Enter your email address"
        sx={inputStyle}
        // Make email read-only if invitedEmail exists
        InputProps={{
          readOnly: !!inviteData?.invitedEmail,
        }}
      />
    </>
  );

  const renderButtonGroup = () => (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: 'auto', padding: '24px 0' }}>
      <Box onClick={handleBack} sx={{ display: 'flex', alignItems: 'center', width: 120 }}>
        <KeyboardBackspaceIcon />
        <Button
          sx={{
            color: '#000453',
            textDecoration: 'underline',
            textTransform: 'none',
            fontWeight: 600,
          }}
        >
          Go back
        </Button>
      </Box>
      <PinkGradientButton
        variant="contained"
        color="primary"
        onClick={handleNext}
        disabled={isNextButtonDisabled}
        sx={{ flexGrow: 1, marginLeft: 1, position: 'relative', maxWidth: 200, m: 0 }}
      >
        Next
      </PinkGradientButton>
    </Box>
  );

  return (
    <OnboardingLayout
      title="Tell us more about yourself"
      subtitle="Please provide us with these details"
      previousStepPath="/onboard/type"
      inputChildren={inputChildren}
      navChildren={renderButtonGroup()}
      currentStep={1}
      userData={userData}
    />
  );
};

export default OnboardNameScreen;
