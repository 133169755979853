import React, { useState } from 'react';
import { Button, TextField, Typography, Box, MenuItem, FormControl, InputLabel, Select } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useNavigate, useLocation } from 'react-router-dom';
import { OnboardingLayout } from './onboard-layout';
import PinkGradientButton from '../../UIControls/PinkGradientButton';
import { inputStyle } from './inputStyle';
import { practiceOrClinicText } from '../../../config';

const OnboardClinicNameScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [userData, setUserData] = useState({...location.state?.userData})
  const searchParams = new URLSearchParams(location.search);
  const isShowOffer = searchParams.get('LVS') === 'true';
  console.warn("userData /clinic-name", userData)

  const handleClinicNameChange = (event) => {
    setUserData({ ...userData, clinicName: event.target.value });
  };

  const handleClinicNumberChange = (event) => {
    const value = event.target.value;
    const re = /^[0-9\b]+$/; // Regex to allow only numbers
    if (value === '' || re.test(value)) {
      setUserData({ ...userData, clinicNumber: value });
    }
  };

  const handleClinicSizeChange = (event) => {
    setUserData({ ...userData, clinicSize: event.target.value });
  };

  const handleNext = () => {
    const nextPath = isShowOffer ? `/onboard/password?LVS=true` : `/onboard/password`;
    navigate(nextPath, { state: { userData } });
  };

  const handleBack = () => {
    const backPath = isShowOffer ? `/onboard/name?LVS=true` : `/onboard/name`;
    navigate(backPath, { state: { userData } });
  };

  const isNextButtonDisabled = !userData.clinicName || !userData.clinicNumber || !userData.clinicSize;

  const renderClinicNameInput = () => (
    <>
      <Typography variant="h6" gutterBottom>
        What's the name of your {practiceOrClinicText}?
      </Typography>
      <TextField
        value={userData.clinicName}
        onChange={handleClinicNameChange}
        fullWidth
        margin="normal"
        placeholder={`Enter ${practiceOrClinicText} name`}
        sx={inputStyle}
      />
    </>
  );

  const renderClinicNumberInput = () => (
    <>
      <Typography variant="h6" gutterBottom>
        What's your {practiceOrClinicText} phone number?
      </Typography>
      <TextField
        type="tel"
        value={userData.clinicNumber}
        onChange={handleClinicNumberChange}
        fullWidth
        margin="normal"
        placeholder={`Enter ${practiceOrClinicText} phone number`}
        sx={inputStyle}
      />
    </>
  );

  const renderPracticeSizeInput = () => (
    <>
      <Typography variant="h6" gutterBottom>
        {practiceOrClinicText[0].toUpperCase() + practiceOrClinicText.slice(1)} size
      </Typography>
      <FormControl fullWidth margin="normal" sx={inputStyle}>
        <InputLabel id="practice-size-label">Select the size of your {practiceOrClinicText}</InputLabel>
        <Select
          labelId="practice-size-label"
          id="practice-size-select"
          value={userData.clinicSize}
          onChange={handleClinicSizeChange}
          sx={{
            ...inputStyle, 
            '& .MuiSelect-select': {
              padding: '16px', 
              border: 0,
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none', 
            }
          }}
        >
          <MenuItem value="0-5">0-5 staff</MenuItem>
          <MenuItem value="6-10">6-10 staff</MenuItem>
          <MenuItem value="11-20">11-20 staff</MenuItem>
          <MenuItem value="20+">20+ staff</MenuItem>
        </Select>
      </FormControl>
    </>
  );

  const renderButtonGroup = () => (
    <Box sx={{ display: 'flex' , alignItems: "center", justifyContent: "space-between", margin: "auto", padding: "24px 0"}}>
      <Box onClick={handleBack} sx={{display: "flex", alignItems: "center", width: 120}}>
        <KeyboardBackspaceIcon/>
        <Button
          sx={{
            color: '#000453',
            textDecoration: 'underline',
            textTransform: 'none',
            fontWeight: 600,
          }}
        >
          Go back
        </Button>
      </Box>
      <PinkGradientButton
        variant="contained"
        color="primary"
        onClick={handleNext}
        disabled={isNextButtonDisabled}
        sx={{ flexGrow: 1, marginLeft: 1, position: 'relative', maxWidth: 200, m: 0 }}
      >
        Next
      </PinkGradientButton>
    </Box>
  );

  return (
    <OnboardingLayout
      title={`Tell us more about your ${practiceOrClinicText}`}
      subtitle="Please provide us with these details"
      inputChildren={<>{renderClinicNameInput()}{renderClinicNumberInput()}{renderPracticeSizeInput()}</>}
      navChildren={renderButtonGroup()}
      currentStep={2}
      previousStepPath="/onboard/name"
      userData={userData}
    />
  );
};

export default OnboardClinicNameScreen;
