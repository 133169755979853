import React from 'react';
import AuthStateObserver from './auth-state-observer';

const AppAuthState = () => {
  const onAuthenticated = (location, navigate) => {
    if (location.pathname.includes('onboard')) {
      console.log('AppAuthState navigating to root');
      navigate('/');
    }
  };

  const onUninitialized = () => {
    // Maybe show a loading spinner or similar
  };

  const onUnauthenticated = (location, navigate) => {
    const searchParams = new URLSearchParams(location.search);

    if (!location.pathname.includes('onboard') && !location.pathname.includes('lvs') && !location.pathname.includes('expo')) {
        console.log('AppAuthState onUnauthenticated navigating to root');

        const inviteId = searchParams.get('inviteId');
        const senderId = searchParams.get('senderId');

        if (inviteId && senderId) {
            navigate({
                pathname: '/',
                search: `?inviteId=${inviteId}&senderId=${senderId}`,
            });
        } else {
            navigate('/');
        }
    }
  };


  const onUnverified = (location, navigate) => {
    if (!location.pathname.includes('onboard/code')) {
      console.log('AppAuthState onUnverified navigating to /onboard/code');
      const userData = location.state?.userData || {};  
      const searchParams = new URLSearchParams(location.search);
      const inviteId = searchParams.get('inviteId');
      const senderId = searchParams.get('senderId');

      navigate({
        pathname: '/onboard/code',
        search: inviteId && senderId ? `?inviteId=${inviteId}&senderId=${senderId}` : '',
        state: { userData },
      });
    }
  };

  const onPendingSubscription = (location, navigate, authState) => {
    if (!location.pathname.includes('onboard/plan') && !location.pathname.includes('onboard/resubscribe') &&!location.pathname.includes('checkout') && authState === 'pending_subscription') {
      console.log('AppAuthState onPendingSubscription navigating to /onboard/code');
      navigate('/onboard/plan');
    }
  };

  const onCancelledSubscriptione = (location, navigate, authState) => {
    if (!location.pathname.includes('onboard/resubscribe') && !location.pathname.includes('checkout') && !location.pathname.includes('settings/manage-subscription') && authState === 'cancelled_subscription') {
      console.log('AppAuthState onCancelledSubscription navigating to /onboard/resubscribe');
      navigate('/onboard/resubscribe');
    }
  };

  return (
    <AuthStateObserver
      onAuthenticated={onAuthenticated}
      onUninitialized={onUninitialized}
      onUnauthenticated={onUnauthenticated}
      onUnverified={onUnverified}
      onPendingSubscription={onPendingSubscription}
      onCancelledSubscription={onCancelledSubscriptione}
    />
  );
};

export default AppAuthState;
