import React, { useEffect, useContext } from 'react';
// import { FinishProfileSetupCard } from './finish-profile-setup-card';
import { Feedback } from './feedback';
import { SearchHome } from './search-home';
import { useNavigate, useLocation } from 'react-router-dom';
import { CenteredWrapper } from '../../UIControls/centered-wrapper';
import RecentlyViewedHandouts from '../handout/recently-viewed-handouts';
import FeaturedHandouts from '../handout/featured-handouts';
import { useSnackbar } from '../SnackbarProvider';
import Explore3DPanel from './explore-3d-panel';
import StoreContext from '../../../react-core-lib/components/app/store-context';

export function HomeScreen() {
    const navigate = useNavigate();
    const location = useLocation();
    const showSnackbar = useSnackbar();
    const { userDataStore } = useContext(StoreContext);

    useEffect(() => {
        if (location?.state?.subscriptionSuccess) {
            showSnackbar(
                'Your subscription details have been successfully updated. Start enjoying the benefits now!',
                'success',
            );
            // clear  state to prevent the message from showing again on subsequent navigations
            navigate(location.pathname, { replace: true, state: {} });
        }
        const trialSuccess = localStorage.getItem('trialSuccess');
        if (trialSuccess) {
            showSnackbar(
                'Your 14 day free professional trial is confirmed. Start enjoying the benefits now!',
                'success',
            );
            localStorage.removeItem('trialSuccess');
        }
    }, [location, showSnackbar, navigate]);

    return (
        <>
            <SearchHome />

            {/* <FinishProfileSetupCard /> */}

            <RecentlyViewedHandouts />

            <FeaturedHandouts />

            {process.env.REACT_APP_CETRA_CLINIC_TYPE === 'vet' &&
                userDataStore?.subscriptionData?.accountType !== 'Student' && <Explore3DPanel />}

            <CenteredWrapper>
                <Feedback />
            </CenteredWrapper>
        </>
    );
}
