export const CATEGORIES = {
    'Body Area': [
        {
            name: 'Abdomen and Pelvis',
            subcategories: ['Abdomen', 'Pelvis', 'Stomach', 'Liver', 'Kidneys', 'Intestines', 'Reproductive Organs'],
        },
        {
            name: 'Cardiovascular System',
            subcategories: [],
        },
        {
            name: 'Head and Neck',
            subcategories: ['Skull', 'Face', 'Eyes', 'Ears', 'Nose', 'Mouth', 'Throat'],
        },
        {
            name: 'Lower Limbs',
            subcategories: ['Hips', 'Thigh', 'Knee', 'Lower Leg', 'Ankle', 'Foot'],
        },
        {
            name: 'Pelvis',
            subcategories: ['Urinary System', 'Reproductive Organs'],
        },
        {
            name: 'Thorax',
            subcategories: ['Chest', 'Back', 'Spine'],
        },
        {
            name: 'Upper Limb',
            subcategories: ['Shoulder', 'Upper Arm', 'Elbow', 'Forearm', 'Wrist', 'Hand', 'Fingers'],
        },
        {
            name: 'Urinary System',
            subcategories: [],
        },
    ],
    Musculoskeletal: [],
    'Chronic conditions': [],
    Diagrams: [],
    '3D Models': [],
    Anatomy: [],
    'General principles': [],
    'Medical conditions': [],
    "Men's health": [],
    Neurology: [],
    Paediatrics: [],
    Cardiorespiratory: [],
    'Serious pathologies': [],
    Rheumatology: [],
    "Women's health": [],
};

export const CATEGORIES_VET = {
    Animal: [
        {
            name: 'Large Animals',
            subcategories: ['Equine', 'Bovine', 'Sheep', 'Caprine', 'Camelid'],
        },
        {
            name: 'Small Animals',
            subcategories: ['Canine', 'Feline', 'Rabbit'],
        },
        {
            name: 'Exotic Animals',
            subcategories: [
                {
                    name: 'Small mammals',
                    subcategories: ['Guinea pig', 'Hamster', 'Rat', 'Mouse'],
                },
                {
                    name: 'Birds',
                    subcategories: ['Chicken'],
                },
                {
                    name: 'Rabbit',
                    subcategories: [],
                },
                {
                    name: 'Reptiles',
                    subcategories: ['Lizard', 'Fish', 'Snake', 'Tortoise', 'Axolotl'],
                },
            ],
        },
    ],
    Behaviour: [],
    Dentistry: [],
    Dermatology: [],
    Diagrams: [],
    '3D Models': [],
    Emergency: [],
    Farm: [],
    'General anatomy': [],
    'General principles': [],
    'Internal medicine': [],
    Neurology: [],
    Ophthalmology: [],
    Orthopaedics: [],
    Parasites: [],
    Reproduction: [],
    Surgery: [],
};
