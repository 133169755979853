import React, { useState, useContext } from 'react';
import CustomToolbarButton from './toolbar/custom-toolbar-button';
import ASEmailIcon from '../../../assets/icons/Email.svg';
import ActionDialog from './action-dialog';
import { useGenerateLink } from './use-generated-link';
import { useSendEmail } from './use-send-email';
import ValidationUtils from '../../../react-core-lib/utils/validation-utils';
import { useTheme } from '@mui/material/styles';
import mixpanel from 'mixpanel-browser';
import SuccessDialog from '../../UIControls/api-button/SuccessDialog';
import ConfirmationDialog from '../../UIControls/ConfirmationDialog';
import ErrorDialog from '../../UIControls/api-button/ErrorDialog';
import StoreContext from '../../../react-core-lib/components/app/store-context';

function HandoutEmailButton({
    handoutData,
    currentUser,
    bundledHandoutsView = false,
    accountType,
    onUpgradeRequired,
    toggleStates,
}) {
    const { userDataStore } = useContext(StoreContext);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [email, setEmail] = useState('');
    const [openErrorDialog, setOpenErrorDialog] = useState(false);
    const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
    const sendEmail = useSendEmail();
    const { generateLink } = useGenerateLink(handoutData, bundledHandoutsView);
    const theme = useTheme();

    const handleDialogSend = async () => {
        try {
            // Generate the linkId
            const linkId = await generateLink();
            const clinicId = userDataStore.userId;

            const result = await sendEmail(
                email,
                bundledHandoutsView ? 'your handouts' : handoutData.contentName,
                linkId,
                clinicId,
                toggleStates,
            );

            // Assuming successful response means the email is sent
            if (result) {
                mixpanel.track('HandoutShared', {
                    email: currentUser.attributes.email,
                    shareMethod: 'Send Email',
                    handoutTitle: handoutData.contentName,
                });
                setOpenSuccessDialog(true);
            } else {
                setOpenErrorDialog(true);
            }
        } catch (error) {
            console.error('Error while sending email:', error);
            setOpenErrorDialog(true);
        }
    };

    const handleClick = () => {
        if (accountType === 'Student') {
            onUpgradeRequired();
        } else {
            setDialogOpen(true);
        }
    };

    return (
        <>
            <CustomToolbarButton
                iconSrc={ASEmailIcon}
                tooltipTitle={bundledHandoutsView ? 'Email handouts' : 'Email handout'}
                onClick={handleClick}
            />
            <ConfirmationDialog
                open={dialogOpen}
                title="Send via email"
                message="Please enter your email address."
                showTextField={true}
                textFieldLabel="Email address"
                textFieldValue={email}
                onTextFieldChange={(e) => setEmail(e.target.value)}
                onConfirm={handleDialogSend}
                validate={ValidationUtils.validateEmail}
                confirmText="Send"
                cancelText="Cancel"
                cancelTextReq={true}
                handleClose={() => setDialogOpen(false)}
            />

            <SuccessDialog
                open={openSuccessDialog}
                handleClose={() => {
                    setOpenSuccessDialog(false);
                }}
                title="Email sent"
                message="The email was sent successfully."
            />
            <ErrorDialog
                open={openErrorDialog}
                handleClose={() => {
                    setOpenErrorDialog(false);
                }}
                title="Email failed to send"
                message="Please try again"
            />
        </>
    );
}

export default HandoutEmailButton;
