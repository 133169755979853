import React, { useContext, useEffect, useState, useRef } from 'react';
import {
    Box,
    Grid,
    Typography,
    Dialog,
    DialogContent,
    IconButton,
    InputBase,
    InputAdornment,
    useTheme,
    Link,
    useMediaQuery,
    Container,
    Button,
} from '@mui/material';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import SearchIcon from '@mui/icons-material/Search';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import StoreContext from '../../../react-core-lib/components/app/store-context';
import Model3DViewerPopup from '../handout/model3DViewerPopup';
import HandoutActions from '../handout/handout-actions';
import { SearchFiltersVet } from '../search/search-filters-vet'; // the filter component
import DownloadUtils from '../../../utils/download-utils';
import UserApi from '../../../rest-api/user-api';
import Model3DIcon from '../../../assets/icons/3D-Preview.jpg';
import { CenteredBox } from '../../UIControls/centered-box'; // CenteredBox component
import { toJS } from 'mobx'; // left for investigation (if I need)

const locale = 'en-UK'; // Define locale here for easy future changes

const Models3DScreen = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { contentStore, imageStore, authStore } = useContext(StoreContext);
    const [models, setModels] = useState([]);
    const [filteredModels, setFilteredModels] = useState([]);
    const [openModel, setOpenModel] = useState(null); // For modal popup
    const [page, setPage] = useState(0); // Current page for pagination
    const modelsPerPage = 6; // 2 per row, 3 rows (total 6 models per page)
    const [imageMap, setImageMap] = useState({}); // To store images fetched from IndexedDB or via download
    const [activeFilter, setActiveFilter] = useState(''); // Active filter state for the categories
    const [searchTerm, setSearchTerm] = useState(''); // Search term state
    const inputRef = useRef(null);
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));
    const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('md'));

    const navigateToFeedback = () => {
        navigate('/feedback');
    };

    useEffect(() => {
        const fetch3DModels = async () => {
            const contentArray = Object.values(contentStore.contentData);
            const modelsList = [];

            for (const item of contentArray) {
                const localeData = item.contentData[locale] || [];
                // Extract all '3dModel' blocks from each handout
                // this is agains thatif more than one 3D model, it was catching only first one. Now it should catch all
                localeData.forEach((section) => {
                    section.blocks.forEach((block) => {
                        if (block.type === '3dModel') {
                            // Add each 3D model block along with its handout
                            modelsList.push({
                                modelBlock: block,
                                handout: item,
                            });
                        }
                    });
                });
            }

            setModels(modelsList);
            setFilteredModels(modelsList); // Initially display all models

            // Fetch images for each model
            for (const model of modelsList) {
                const imageSrc = model.modelBlock?.imageSrc;

                if (imageSrc) {
                    const imageObject = await imageStore.getImageFromIndexedDB(imageSrc);

                    if (!imageObject) {
                        try {
                            const payload = {
                                files: [{ method: 'get', key: imageSrc }],
                                contentCategory: 'image',
                            };
                            const presignedUrl = await UserApi.generatedPresignedUrls(authStore.idToken, payload);

                            const downloadResult = await DownloadUtils.downloadImageFromPresignedUrl(
                                presignedUrl.getUrls[0]['presignedUrl'],
                            );

                            if (downloadResult && downloadResult.base64data) {
                                const base64data = downloadResult.base64data;
                                const etag = downloadResult.etag;

                                // Store the image in IndexedDB
                                await imageStore.saveImageToIndexedDB(imageSrc, base64data, etag);
                                setImageMap((prev) => ({ ...prev, [imageSrc]: base64data }));
                            } else {
                                setImageMap((prev) => ({ ...prev, [imageSrc]: Model3DIcon })); // Fallback image
                            }
                        } catch (error) {
                            console.error(`Error fetching image: ${error}`);
                            setImageMap((prev) => ({ ...prev, [imageSrc]: Model3DIcon })); // Fallback image
                        }
                    } else {
                        setImageMap((prev) => ({ ...prev, [imageSrc]: imageObject.data })); // Image in IndexedDB
                    }
                } else {
                    setImageMap((prev) => ({ ...prev, [imageSrc]: Model3DIcon })); // Fallback if no imageSrc
                }
            }
        };

        if (contentStore.isInitialized) {
            fetch3DModels();
        }
    }, [contentStore, imageStore, authStore]);

    useEffect(() => {
        let updatedModels = models;

        // Apply filter based on the selected category, only if a filter is active
        if (activeFilter) {
            updatedModels = models.filter((model) => {
                const categories = model.handout.categories || {};
                const animalCategory = categories['Animal'] || {};

                // Check if the activeFilter exists in the animal categories
                for (const category in animalCategory) {
                    if (animalCategory[category].includes(activeFilter)) {
                        return true; // Filter match
                    }
                }

                return false; // No match found
            });
        }

        // Apply search filtering
        if (searchTerm) {
            updatedModels = updatedModels.filter((model) =>
                model.contentName.toLowerCase().includes(searchTerm.toLowerCase()),
            );
        }

        // If no filter or search term, show all models
        if (!activeFilter && !searchTerm) {
            updatedModels = models; // Reset to all models
        }

        // Update filtered models
        setFilteredModels(updatedModels);
    }, [models, activeFilter, searchTerm]);

    const handleNextPage = () => {
        if ((page + 1) * modelsPerPage < filteredModels.length) {
            setPage(page + 1);
        }
    };

    const handlePrevPage = () => {
        if (page > 0) {
            setPage(page - 1);
        }
    };

    const handleSearchInputChange = (value) => {
        setSearchTerm(value);
    };

    const openModelPopup = (model) => {
        setOpenModel(model);
    };

    const closeModelPopup = () => {
        setOpenModel(null);
    };

    // Update filtered models when the filter or search term changes
    const handleFilterChange = (newFilter) => {
        // If the same filter is clicked again, reset the filter to show all models (empty filter)
        if (activeFilter === newFilter || newFilter === 'Condition') {
            setActiveFilter(''); // Clear filter when toggled off
        } else {
            setActiveFilter(newFilter); // Set new filter when selected
        }
        setPage(0); // Reset pagination
    };

    const paginatedModels = filteredModels.slice(page * modelsPerPage, (page + 1) * modelsPerPage);

    return (
        <>
            {/* Gradient Background Box */}
            <Box
                display="flex"
                alignItems="center"
                justifyItems="center"
                flexDirection="column"
                paddingY={isMobile ? '48px' : '40px'}
                paddingBottom={'40px'}
                sx={{
                    width: '100%',
                    borderRadius: '4px',
                    background: 'linear-gradient(132deg, #FA96C3 -2.07%, #FF669B 15.82%, #BD8DE8 116.64%)',
                    height: isMobile ? 'initial' : '450px',
                    position: 'relative', // Added to position pagination arrows
                }}
                className="search-banner"
            >
                {/* Centered Headers and Search Bar */}
                <CenteredBox sx={{ margin: 'auto' }}>
                    <Typography variant="h3" align="center" color="white">
                        Interactive 3D Models
                    </Typography>
                    <Typography variant="h6" align="center" color="white" mb={'-16px'}>
                        Transform client understanding
                    </Typography>
                    {/* Search Bar */}
                    <Box
                        display="flex"
                        alignItems="center"
                        width="100%"
                        position="relative"
                        sx={{
                            backgroundColor: searchTerm ? 'white' : 'transparent',
                            borderRadius: searchTerm ? '8px 8px 0 0' : '8px',
                            transition: 'backgroundColor 0.2s',
                            padding: isTablet ? 2 : 0,
                        }}
                        className="search-container"
                    >
                        <InputBase
                            fullWidth
                            placeholder="Search..."
                            value={searchTerm}
                            onChange={(e) => handleSearchInputChange(e.target.value)}
                            inputRef={inputRef}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton>
                                        <SearchIcon sx={{ color: searchTerm ? 'black' : 'white' }} />
                                    </IconButton>
                                </InputAdornment>
                            }
                            sx={{
                                p: 1,
                                borderRadius: 8,
                                border: 'none',
                                color: searchTerm ? 'black' : 'white',
                                paddingLeft: '14px',
                                background: 'rgba(255,255,255,0.1)',
                                '&::placeholder': { color: 'white' },
                                '& .MuiInputBase-input': { color: searchTerm ? 'black' : 'white' },
                            }}
                        />
                    </Box>
                    {/* Filters section */}
                    {process.env.REACT_APP_CETRA_CLINIC_TYPE === 'vet' && (
                        <Box marginTop={1} marginX={isMobile ? 2 : 0} sx={{ color: 'white' }}>
                            <Box display="flex" alignItems="center" width="100%">
                                Browse by
                            </Box>
                            <SearchFiltersVet
                                activeFilter={activeFilter}
                                onFilterChange={handleFilterChange}
                                homepageView={true}
                            />
                        </Box>
                    )}
                </CenteredBox>
            </Box>

            {/* Go Back Button */}
            <Box sx={{ maxWidth: '66.66%', margin: '0 auto', mt: 2 }}>
                <Box
                    sx={{
                        width: isMobile ? '100%' : 'auto',
                        maxWidth: isMobile ? 'initial' : 'fit-content',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <Button
                        startIcon={<ArrowBackIcon sx={{ color: 'white' }} />}
                        onClick={() => navigate('/')}
                        variant="contained"
                        sx={{
                            backgroundColor: '#292F68',
                            padding: '20px 24px',
                            borderRadius: '36px',
                            color: 'white',
                            width: isMobile ? '100%' : 'auto',
                            textTransform: 'none',
                        }}
                    >
                        Go back
                    </Button>
                </Box>
            </Box>

            {/* Content Section */}
            <Box sx={{ position: 'relative', mt: 4 }}>
                {/* Grid Container with Pagination Controls */}
                <Box sx={{ maxWidth: '66.66%', margin: '0 auto', position: 'relative' }}>
                    <Grid container spacing={3}>
                        {paginatedModels.length === 0 ? (
                            <Grid item xs={12}>
                                <Box
                                    sx={{
                                        textAlign: 'center',
                                        width: '100%',
                                        padding: '16px',
                                        boxSizing: 'border-box',
                                    }}
                                >
                                    <Typography variant="h6" color="text.secondary">
                                        No models available.
                                    </Typography>
                                </Box>
                            </Grid>
                        ) : (
                            paginatedModels.map((model, index) => {
                                const modelBlock = model.modelBlock;
                                const handout = model.handout;
                                const imageSrc = modelBlock?.imageSrc;

                                return (
                                    <Grid item xs={12} md={6} key={index}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                            }}
                                        >
                                            {/* Image and Play Icon */}
                                            <Box
                                                sx={{
                                                    position: 'relative',
                                                    width: '100%',
                                                    borderRadius: '8px',
                                                    overflow: 'hidden',
                                                }}
                                            >
                                                <img
                                                    src={imageMap[imageSrc] || Model3DIcon}
                                                    alt={modelBlock?.alt}
                                                    style={{ width: '100%', height: 'auto', display: 'block' }}
                                                />
                                                <PlayCircleFilledWhiteIcon
                                                    sx={{
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '50%',
                                                        transform: 'translate(-50%, -50%)',
                                                        fontSize: '64px',
                                                        color: 'white',
                                                        opacity: 0.8,
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() => openModelPopup(model)}
                                                />
                                            </Box>

                                            {/* White Panel with Model Title */}
                                            <Box
                                                sx={{
                                                    width: '100%',
                                                    backgroundColor: 'white',
                                                    padding: '8px',
                                                    boxSizing: 'border-box',
                                                    borderRadius: '8px',
                                                    marginTop: '16px', // Set margin-top to 1/3 of the panel's height
                                                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                                                }}
                                            >
                                                <Typography variant="subtitle1" align="center" color={'#000453'}>
                                                    {modelBlock.title || handout.contentName}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                );
                            })
                        )}
                    </Grid>

                    {/* Pagination Controls within Grid Container */}
                    <IconButton
                        onClick={handlePrevPage}
                        disabled={page === 0}
                        sx={{
                            position: 'absolute',
                            left: '-60px', // made it close as better looks on mobile
                            top: '50%',
                            transform: 'translateY(-50%)',
                            zIndex: 10,
                            backgroundColor: 'rgba(0, 4, 83, 0.08)', // 8% opacity of #000453 - taken from Figma
                            width: '50px',
                            height: '50px',
                            borderRadius: '50%',
                            '&:hover': {
                                backgroundColor: 'rgba(0, 4, 83, 0.12)', // when a cursor above make it darker
                            },
                        }}
                    >
                        <ChevronLeftRoundedIcon sx={{ fontSize: '32px', color: '#000453' }} />
                    </IconButton>

                    <IconButton
                        onClick={handleNextPage}
                        disabled={(page + 1) * modelsPerPage >= filteredModels.length}
                        sx={{
                            position: 'absolute',
                            right: '-60px', // made it close as better looks on mobile
                            top: '50%',
                            transform: 'translateY(-50%)',
                            zIndex: 10,
                            backgroundColor: 'rgba(0, 4, 83, 0.08)', // 8% opacity of #000453 - taken from Figma
                            width: '50px',
                            height: '50px',
                            borderRadius: '50%',
                            '&:hover': {
                                backgroundColor: 'rgba(0, 4, 83, 0.12)', // when a cursor above make it darker
                            },
                        }}
                    >
                        <ChevronRightRoundedIcon sx={{ fontSize: '32px', color: '#000453' }} />
                    </IconButton>
                </Box>
            </Box>
            {/* Modal for 3D Model Viewer */}
            {openModel && (
                <Dialog
                    open={true}
                    onClose={closeModelPopup}
                    maxWidth="lg"
                    fullWidth
                    sx={{
                        '& .MuiDialog-paper': {
                            borderRadius: '24px', // More rounded corners
                            overflow: 'hidden',
                        },
                    }}
                >
                    <DialogContent
                        sx={{
                            padding: 0, // forcing to remove padding
                            margin: 0,
                            position: 'relative',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <>
                            <Model3DViewerPopup
                                uid={openModel.modelBlock.uid}
                                alt={openModel.modelBlock.alt}
                                contentId={openModel.handout.contentId}
                            />

                            {/* Handout Actions (Add to Bundle, Share) */}
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    width: '100%',
                                    padding: '16px',
                                }}
                            >
                                {/* Left side: Add to My Fav handouts / bundle */}
                                <Box sx={{ flexGrow: 1 }}>
                                    <HandoutActions handoutData={openModel.handout} is3DViewerOpen={true} />
                                </Box>
                            </Box>
                        </>
                    </DialogContent>
                </Dialog>
            )}
            {/* Credits Section */}
            {process.env.REACT_APP_CETRA_CLINIC_TYPE === 'vet' && (
                <Box sx={{ marginTop: '40px', textAlign: 'center' }}>
                    <Typography variant="body1">
                        Made in collaboration with:
                        <Link
                            href="https://sketchfab.com/GaiaBiomodels"
                            target="_blank"
                            rel="noopener"
                            sx={{ marginLeft: '4px', textDecoration: 'none', color: '#1976d2' }}
                        >
                            GAIA Biomodels
                        </Link>
                    </Typography>
                </Box>
            )}
            {/* New Feedback Section */}
            <Container
                component={'section'}
                maxWidth={isMobileOrTablet ? 'md' : 'xl'}
                sx={{ position: 'relative', pt: 4 }}
            >
                <Box
                    bgcolor="#000453"
                    p={0}
                    borderRadius={2}
                    display="flex"
                    flexDirection={{ xs: 'column', md: 'row' }}
                    alignItems="stretch"
                    justifyContent="space-between"
                    sx={{
                        height: { xs: 'auto', md: 246 },
                        overflow: 'hidden',
                    }}
                >
                    {/* Left Half */}
                    <Box
                        sx={{
                            flex: 1,
                            paddingLeft: { xs: 2, md: 8 },
                            paddingTop: { xs: 2, md: 0 },
                            paddingBottom: { xs: 2, md: 0 },
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                        }}
                    >
                        <Typography
                            variant="h3"
                            align="left"
                            color="white"
                            sx={{
                                [theme.breakpoints.down('md')]: {
                                    fontSize: '1.5rem',
                                },
                            }}
                        >
                            Let us know
                        </Typography>
                        <Typography
                            variant="h3"
                            align="left"
                            color="white"
                            sx={{
                                [theme.breakpoints.down('md')]: {
                                    fontSize: '1.5rem',
                                },
                            }}
                        >
                            your thoughts!
                        </Typography>
                    </Box>
                    {/* Right Half */}
                    <Box
                        sx={{
                            flex: 1,
                            padding: { xs: 2, md: 5 },
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                        }}
                    >
                        <Typography color="white">
                            We value your thoughts. Any feedback on your experience is appreciated.
                        </Typography>
                        <Button
                            endIcon={<ArrowForwardIcon />}
                            onClick={navigateToFeedback}
                            variant="contained"
                            sx={{
                                backgroundColor: '#292F68',
                                padding: '20px 24px',
                                borderRadius: '36px',
                                width: 200,
                                mt: 2,
                            }}
                        >
                            Give feedback
                        </Button>
                    </Box>
                </Box>
            </Container>
        </>
    );
};

export default Models3DScreen;
