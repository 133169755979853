import { useContext } from 'react';
import StoreContext from '../../../react-core-lib/components/app/store-context';
import UserApi from '../../../rest-api/user-api';

export function useSendEmail() {
    const { authStore } = useContext(StoreContext);

    const sendEmail = async (emailAddress, contentName, linkId, clinicId, toggleStates) => {
        try {
            const payload = {
                toEmailAddress: emailAddress,
                contentName: contentName,
                linkId: linkId,
                clinicId: clinicId,
                toggleStates: toggleStates,
            };

            const result = await UserApi.sendPatientEmail(authStore.idToken, payload);

            if (!result) {
                throw new Error(`Failed to send email: ${result.statusText || 'Unknown error'}`);
            }

            return result;
        } catch (error) {
            console.error('Error sending email:', error);
            throw error; // Re-throw the error to handle it in the caller
        }
    };

    return sendEmail;
}
