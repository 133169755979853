import React, { useState, useContext } from 'react';
import { Button, Box, ToggleButton, ToggleButtonGroup, Divider, Typography, useTheme, useMediaQuery } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { styled } from '@mui/system';
import { useNavigate, useLocation } from 'react-router-dom';
import { OnboardingLayout } from './onboard-layout';
import PinkGradientButton from '../../UIControls/PinkGradientButton';
import SubscriptionCard from '../../UIControls/SubscriptionCard';
import subscriptionPlans from "../../authenticated/settings/subscriptionPlans.js";
import StoreContext from '../../../react-core-lib/components/app/store-context.js';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    borderRadius: 50,
    overflow: 'hidden',
    boxShadow: '0 0 0 1px rgba(0, 0, 0, 0.12)',
    width: 205
}));
  
const StyledToggleButton = styled(ToggleButton)(({ theme, selected }) => ({
    color: selected ? '#fff' : '#000',
    backgroundColor: selected ? '#000453' : '#fff',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: selected ? '#000453' : '#f4f4f4',
    },
    '&.Mui-selected': {
      backgroundColor: '#000453',
      color: '#fff',
      '&:hover': {
        backgroundColor: '#000453',
      },
    },
    border: 'none',
    borderRadius: 0,
    flex: 1,
}));

const OnboardPlanScreen = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const location = useLocation();
  const planKeys = Object.keys(subscriptionPlans);
  const appStateStore = useContext(StoreContext);
  const { authStore, userDataStore } = useContext(StoreContext);
  const [userData, setUserData] = useState({...location.state?.userData});
  const [selectedPlan, setSelectedPlan] = useState(null);
  const isShowOffer = localStorage.getItem('LVS') === 'true';
  const [billingCycle, setBillingCycle] = useState(!isShowOffer ? 'monthly' : 'annually');
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  console.warn("userData /plan", userData);

  const handleBack = async () => {
    await authStore.logout();
    await appStateStore.clearUserStores();
    localStorage.clear();  
    navigate('/');
  };

  const handleCardClick = (plan) => {
    console.warn(plan)
    setSelectedPlan(plan);    
  };

  const handleBillingCycleChange = (event, newCycle) => {
    if (newCycle !== null) {
      setBillingCycle(newCycle);
    }
  };

  const handleNext = () => {
    const path = isShowOffer ? '/checkout?LVS=true' : '/checkout';
    navigate(path, { state: { userData, plan: selectedPlan, billingCycle: billingCycle } });
  };
  
  const isNextButtonDisabled = selectedPlan === null;

  const inputChildren = (
    <>
      {!isShowOffer && (
        <>
          <SubscriptionCard plan={"Professional - 14 day FREE trial"} billingCycle={billingCycle} showSelect={true} sx={{ color: "white!important" }} />
          <Divider sx={{ mb: 4 }} />
        </>
      )}

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>
        <Typography variant="body1" sx={{ maxWidth: 400 }}>
          Opt for our annual plan for an extra saving! Enjoy additional seats, more features and priority support all year long.
        </Typography>
        <StyledToggleButtonGroup
          value={billingCycle}
          exclusive
          onChange={handleBillingCycleChange}
          aria-label="billing cycle"
          sx={{ marginTop: isMobile ? 2 : 0 }}
        >
          <StyledToggleButton value="monthly" aria-label="monthly" selected={billingCycle === 'monthly'}>
            Monthly
          </StyledToggleButton>
          <StyledToggleButton value="annually" aria-label="annually" selected={billingCycle === 'annually'}>
            Annually
          </StyledToggleButton>
      </StyledToggleButtonGroup>
      </Box>

      <Box sx={{ textAlign: 'left', marginTop: '24px' }}>
        {planKeys.filter(plan => !plan.includes('trial')).map((plan, index) => (
          <Box key={index} onClick={() => handleCardClick(plan)} sx={{ cursor: 'pointer' }}>
            <SubscriptionCard plan={plan} billingCycle={billingCycle} discount={isShowOffer && billingCycle === 'annually' ? 73: 50} sx={{ background: plan === selectedPlan ? 'linear-gradient(to right, #ff4081, #ff80ab)' : 'transparent', padding: plan === selectedPlan ? '5px' : '0' }} isExpo={isShowOffer && process.env.REACT_APP_CETRA_CLINIC_TYPE === 'physio' }/>
          </Box>
        ))}
      </Box>
    </>
  );

  const renderButtonGroup = () => (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: 'auto', padding: '24px 0' }}>
      <Box onClick={handleBack} sx={{ display: "flex", alignItems: "center", width: 120 }}>
        <KeyboardBackspaceIcon />
        <Button
          sx={{
            color: '#000453',
            textDecoration: 'underline',
            textTransform: 'none',
            fontWeight: 600,
          }}
        >
          Go back
        </Button>
      </Box>
      <PinkGradientButton
        variant="contained"
        color="primary"
        onClick={handleNext}
        disabled={isNextButtonDisabled}
        sx={{ flexGrow: 1, marginLeft: 1, position: 'relative', maxWidth: 200, m: 0 }}
      >
        Next
      </PinkGradientButton>
    </Box>
  );

  return (
    <OnboardingLayout
      title="Here's what we recommend"
      subtitle={!isShowOffer ? "Try out our professional plan which is free for 14-days, or dive right in and subscribe to your preferred plan." : ""}
      previousStepPath="/onboard/code"
      inputChildren={inputChildren}
      navChildren={renderButtonGroup()}
      currentStep={5}
      userData={userData}
      backgroundColor={false}
    />
  );
};

export default OnboardPlanScreen;
