import { useContext } from 'react';
import StoreContext from '../../../react-core-lib/components/app/store-context';
import UserApi from '../../../rest-api/user-api';

export function useGenerateLink(handoutData, isBundled = false) {
    const { authStore, userDataStore } = useContext(StoreContext);
    const clinicType = process.env.REACT_APP_CETRA_CLINIC_TYPE;

    // Process handout data to ensure it is in the correct format for the API call
    const processHandoutData = () => (isBundled ? handoutData.map((data) => data.contentId) : [handoutData.contentId]);

    const generateLink = async () => {
        const contentIds = processHandoutData();
        console.warn('Content IDs to process:', contentIds);

        try {
            const result = await UserApi.generatePatientLink(authStore.idToken, contentIds);
            const linkId = result.linkId; // The UUID

            const userId = userDataStore.userId;
            let linkWithClinicId = `${result.linkId}&clinicId=${userId}`;
            // Cache the link generation details - but do we use this? do no tsee: localStorage.getItem('contentLink'
            const dataToCache = {
                linkId,
                linkWithClinicId,
                timestamp: new Date().getTime(),
            };
            localStorage.setItem('contentLink', JSON.stringify(dataToCache));

            return linkId; // returning the UUID, the name if the function is confusing (as this is "Only")
        } catch (error) {
            console.error('Error generating link:', error);
            throw new Error('Failed to generate link');
        }
    };

    const generateLinkOnly = async (toggleStates = {}) => {
        const linkId = await generateLink();

        const userId = userDataStore.userId;
        const url = new URL(process.env.REACT_APP_PATIENT_HANDOUT_URL);

        url.searchParams.append('linkId', linkId);
        url.searchParams.append('clinicId', userId);

        // If toggles are off, add them to the URL
        const { show3DModel, showMainIllustration, showTutorialVideo } = toggleStates;
        if (show3DModel === false) url.searchParams.append('show3DModel', 'false');
        if (showMainIllustration === false) url.searchParams.append('showMainIllustration', 'false');
        if (showTutorialVideo === false) url.searchParams.append('showTutorialVideo', 'false');

        return url.toString();
    };

    const createShareData = async (toggleStates = {}) => {
        const link = await generateLinkOnly(toggleStates);
        const title = `Cetra ${clinicType === 'vet' ? 'Vets' : 'Health'} Information Sheet(s)`;
        const handoutTitle = handoutData.contentName;

        let messageSingleVet = `Please read your ${handoutTitle} handout by clicking the link below. This handout contains valuable information around your pet's condition. If you have any questions about your pet's condition, please don't hesitate to reach out directly - We're here to help! \n\n ${link}`;

        let messageBundledVet = `As discussed in your consultation, these handouts contain valuable information around your pet's condition. \n\n Please take a moment to open and read the attached handouts. If you have any questions about your pet's condition, please don't hesitate to reach out directly to us - We're here to help! \n\n ${link}`;

        let messageSinglePhysio = `Please read your ${handoutTitle} handout by clicking the link below. This handout contains valuable information around your condition. If you have any questions about your condition, please don't hesitate to reach out directly - We're here to help!\n\n ${link}`;

        let messageBundledPhysio = `As discussed in your consultation, these handouts contain valuable information around your condition. \n\n Please take a moment to open and read the attached handouts. If you have any questions about your condition, please don't hesitate to reach out directly to us - We're here to help! \n\n ${link}`;

        let messageSingle = clinicType === 'vet' ? messageSingleVet : messageSinglePhysio;
        let messageBundled = clinicType === 'vet' ? messageBundledVet : messageBundledPhysio;

        const message = isBundled ? messageBundled : messageSingle;

        return { title, url: link, message };
    };

    return { generateLink, generateLinkOnly, createShareData };
}
