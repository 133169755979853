import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Typography, Divider, IconButton, TextField, Button } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import {
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
    useStripe,
    useElements,
    Elements,
    PaymentRequestButtonElement,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import EditIcon from '../../../assets/icons/Edit.png';
import CloseIcon from '../../../assets/icons/Close.png';
import PinkGradientButton from '../../UIControls/PinkGradientButton';
import StoreContext from '../../../react-core-lib/components/app/store-context';
import PaymentApi from '../../../rest-api/payment-api';
import { useSnackbar } from '../SnackbarProvider';
import ConfirmationDialog from '../../UIControls/ConfirmationDialog';
import UserApi from '../../../rest-api/user-api';
import KlaviyoApi from '../../../rest-api/klaviyo-api';

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`);

const stripeElementStyle = {
    mb: 2,
    '& .MuiOutlinedInput-root': {
        borderRadius: '12px',
        backgroundColor: '#F9F9F9',
        '& fieldset': {
            border: 'none',
        },
    },
    '& .MuiOutlinedInput-input': {
        padding: '16px',
        border: 0,
        color: 'black',
    },
};

const SettingsBilling = ({
    currentPaymentMethod,
    plan = null,
    editorActiveDefault = false,
    onPromoDiscountChange,
    showPromo,
    isShowOffer,
    isExpo,
}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [editorActive, setEditorActive] = useState(editorActiveDefault);
    const [cardholderName, setCardholderName] = useState('');
    const [billingAddress, setBillingAddress] = useState({
        line1: '',
        line2: '',
        city: '',
        postal_code: '',
        country: 'GB',
    });
    const [planDetails, setPlanDetails] = useState(plan);
    const [paymentDetails, setPaymentDetails] = useState(null);
    const stripe = useStripe();
    const elements = useElements();
    const { authStore, userDataStore } = useContext(StoreContext);
    const showSnackbar = useSnackbar();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [promoCode, setPromoCode] = useState('');
    const [promoMessage, setPromoMessage] = useState(null);
    const [promoStatus, setPromoStatus] = useState(null);
    const [promoDiscount, setPromoDiscount] = useState(50); // default 50% off until further notice

    const [paymentRequest, setPaymentRequest] = useState(null);

    console.warn('plan: ', planDetails);

    useEffect(() => {
        if (isShowOffer && process.env.REACT_APP_CETRA_CLINIC_TYPE === 'vet') {
            setPromoCode('LVS');
            handleValidatePromoCode();
        }
    }, [isShowOffer]);

    useEffect(() => {
        if (stripe && editorActiveDefault) {
            const discountedPrice = planDetails?.price - planDetails?.price * (promoDiscount / 100);
            console.warn('discountedPrice: ', discountedPrice);
            const amountInPence = Math.round(discountedPrice * 100);
            console.warn('discountedPrice in pence: ', amountInPence);

            const pr = stripe.paymentRequest({
                country: 'GB',
                currency: 'gbp',
                total: {
                    label: 'Total',
                    amount: amountInPence,
                },
                requestPayerName: false,
                requestPayerEmail: false,
            });

            console.warn('pr start', pr);

            pr.canMakePayment().then((result) => {
                console.warn('pr then', pr);
                if (result) {
                    setPaymentRequest(pr);
                }
            });
        }
    }, [stripe, planDetails, promoDiscount]);

    const fetchPaymentMethod = async () => {
        try {
            const stripeCustomerId = userDataStore?.subscriptionData?.stripeCustomerId;
            if (stripeCustomerId) {
                const paymentDetails = await PaymentApi.getPaymentDetails(authStore.idToken, stripeCustomerId);
                console.warn('Customer payment details: ', paymentDetails);
                setPaymentDetails(paymentDetails);
            }
        } catch (error) {
            console.error('Error fetching payment method:', error);
        }
    };

    useEffect(() => {
        fetchPaymentMethod();
    }, [userDataStore, authStore]);

    useEffect(() => {
        if (plan) {
            console.warn('plan details!', plan);
            setPlanDetails(plan);
        }
    }, [plan]);

    const handleValidatePromoCode = async () => {
        console.warn(`Applying promo code: ${promoCode}`);
        setPromoMessage(null);
        setPromoStatus(null);
        setPromoDiscount(null);
        onPromoDiscountChange(50);
        const req = {
            promoCode: promoCode,
        };

        try {
            const validatePromoResponse = await PaymentApi.validatePromo(authStore.idToken, req);
            console.warn('Promo validation response:', validatePromoResponse);

            // Check if statusCode is 200 for success
            if (validatePromoResponse.statusCode === 200) {
                setPromoMessage('Promo code successfully applied!');
                setPromoStatus('success');
                if (validatePromoResponse.promotionCode?.coupon?.percent_off) {
                    const discount = validatePromoResponse.promotionCode?.coupon?.percent_off;
                    setPromoDiscount(discount);
                    onPromoDiscountChange(discount);
                }
            } else {
                setPromoMessage(validatePromoResponse.error || 'Failed to apply promo code.');
                setPromoStatus('error');
            }
        } catch (error) {
            console.error('Error during promo validation:', error);
            setPromoMessage('An error occurred while applying the promo code.');
            setPromoStatus('error');
        }
    };

    const handleBack = async () => {
        navigate(-1);
    };

    const handleConfirmSubscription = async () => {
        console.log('Subscription attempt with', plan);
        await handleSubmitPayment({ preventDefault: () => {} });
    };

    const handlePaymentRequest = async (event) => {
        event.preventDefault();

        if (!stripe || !paymentRequest) {
            return;
        }

        const { error } = await paymentRequest.show();
        if (error) {
            console.error('Error showing payment request:', error);
            showSnackbar('Failed to initiate payment request.', 'error');
        }
    };

    const handleSubmitPayment = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        const cardElement = elements.getElement(CardNumberElement);

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
            billing_details: {
                name: cardholderName,
                address: billingAddress,
                email: userDataStore.userData.emailAddress,
                phone: userDataStore.userData.mobile,
            },
        });

        if (error) {
            console.error(error);
        } else {
            await sendPaymentDetailsToBackend(paymentMethod, plan);
        }
    };

    const sendPaymentDetailsToBackend = async (paymentMethod, plan) => {
        try {
            const req = {
                userId: userDataStore.userId,
                paymentMethodId: paymentMethod.id,
                email: userDataStore.userData.emailAddress,
                ...(plan && { plan }),
                ...(promoStatus === 'success' && promoCode ? { promoCode } : {}),
            };

            const bearerToken = authStore.idToken;

            const response = await PaymentApi.updatePaymentDetails(bearerToken, req);

            console.warn('Payment details submission response: ', response);

            let updatedSubscriptionData = { ...userDataStore.subscriptionData };

            // Update customerId if returned
            if (response.customerId) {
                updatedSubscriptionData = {
                    ...updatedSubscriptionData,
                    stripeCustomerId: response.customerId,
                };
            }

            if (response.requiresAction) {
                showSnackbar('Payment pending - please complete the security check with your bank', 'success');
                const result = await stripe.confirmCardPayment(response.paymentIntentClientSecret);
                if (result.error) {
                    showSnackbar('3D Secure authentication failed.', 'error');
                } else if (result.paymentIntent.status === 'succeeded') {
                    await confirmPaymentIntent(result.paymentIntent.id, response.subscriptionId);
                } else if (result.paymentIntent.status === 'requires_confirmation') {
                    await confirmPaymentIntent(result.paymentIntent.id, response.subscriptionId);
                }
            } else if (response.subscription) {
                // Handle subscription success
                const { id, status, items, current_period_end } = response.subscription;

                updatedSubscriptionData = {
                    ...updatedSubscriptionData,
                    accountType: plan?.accountType || null,
                    trialActive: false,
                    trialEndDate: Date.now(),
                    subscriptionEndDate: current_period_end * 1000 || null, // Convert seconds to milliseconds
                    cancelled: false,
                    stripeCustomerId: response.customerId || updatedSubscriptionData.stripeCustomerId,
                };

                console.warn('sub data post stripe: ', updatedSubscriptionData);
            }

            // Update user data if changes occurred
            if (response.customerId || response.subscription) {
                await UserApi.updateClinicianData(authStore.idToken, { subscriptionData: updatedSubscriptionData });
                userDataStore.setUserData({
                    ...userDataStore.getUserData(),
                    subscriptionData: updatedSubscriptionData,
                });
                authStore.checkAndSetAuthState(userDataStore.userData.emailAddress, authStore.idToken);
                await KlaviyoApi.updateUserInKlaviyo(
                    authStore.idToken,
                    userDataStore.userData.emailAddress,
                    planDetails?.accountType,
                );
                if (response.subscription) {
                    navigate('/', { state: { subscriptionSuccess: true } });
                } else {
                    showSnackbar('Billing details have been successfully updated.', 'success');
                }
            }
        } catch (error) {
            console.error('Error sending payment details to backend:', error);
            showSnackbar('Error when updating billing details, please try again.', 'error');
        }
    };

    const confirmPaymentIntent = async (paymentIntentId, subscriptionId) => {
        console.warn('Check this Payment Intent: ', paymentIntentId);
        try {
            const req = {
                paymentIntentId,
                userId: userDataStore.userId,
                subscriptionId: subscriptionId,
            };

            const bearerToken = authStore.idToken;

            const response = await PaymentApi.confirmPaymentDetails(bearerToken, req);

            let updatedSubscriptionData = { ...userDataStore.subscriptionData };

            // Update customerId if returned
            if (response.customerId) {
                updatedSubscriptionData = {
                    ...updatedSubscriptionData,
                    stripeCustomerId: response.customerId,
                };
            }

            if (response.subscription) {
                const { id, status, items, current_period_end } = response.subscription;

                updatedSubscriptionData = {
                    ...updatedSubscriptionData,
                    accountType: planDetails?.accountType || null,
                    trialActive: false,
                    trialEndDate: Date.now(),
                    subscriptionEndDate: current_period_end * 1000 || null,
                    cancelled: false,
                    stripeCustomerId: response.customerId || updatedSubscriptionData.stripeCustomerId,
                };

                console.warn('subscription data post stripe: ', updatedSubscriptionData);
            }

            // Update user data if changes occurred
            if (response.customerId || response.subscription) {
                await UserApi.updateClinicianData(authStore.idToken, { subscriptionData: updatedSubscriptionData });
                userDataStore.setUserData({
                    ...userDataStore.getUserData(),
                    subscriptionData: updatedSubscriptionData,
                });
                authStore.checkAndSetAuthState(userDataStore.userData.emailAddress, authStore.idToken);
                await KlaviyoApi.updateUserInKlaviyo(
                    authStore.idToken,
                    userDataStore.userData.emailAddress,
                    planDetails?.accountType,
                );
                if (response.subscription) {
                    navigate('/', { state: { subscriptionSuccess: true } });
                }
                // Re-fetch payment methods to update state
                await fetchPaymentMethod();
            }
        } catch (error) {
            console.error('Error confirming payment intent:', error);
            showSnackbar('Error when confirming payment.', 'error');
        }
    };

    const renderStripeField = () => {
        const { card, billing_details } = paymentDetails?.paymentMethods[0] || {};

        return (
            <Box key="cardDetails" sx={{ position: 'relative' }}>
                <Box sx={{ mb: 2 }}>
                    <Typography variant="body1" sx={{ mb: 1 }}>
                        Card number
                    </Typography>
                    {editorActive ? (
                        <Box
                            sx={{
                                borderRadius: '8px',
                                padding: '16px',
                                backgroundColor: '#f9f9f9',
                                height: '55px',
                            }}
                        >
                            <CardNumberElement />
                        </Box>
                    ) : (
                        <Typography variant="body1" sx={{ fontSize: '16px', color: '#32325d' }}>
                            **** **** **** {card?.last4 || '****'}
                        </Typography>
                    )}
                </Box>

                <Box sx={{ mb: 2 }}>
                    <Typography variant="body1" sx={{ mb: 1 }}>
                        Name on card
                    </Typography>
                    {editorActive ? (
                        <TextField
                            variant="outlined"
                            fullWidth
                            value={cardholderName}
                            onChange={(e) => setCardholderName(e.target.value)}
                            placeholder="Cardholder name"
                            sx={stripeElementStyle}
                        />
                    ) : (
                        <Typography variant="body1" sx={{ fontSize: '16px', color: '#32325d' }}>
                            {billing_details?.name || '********'}
                        </Typography>
                    )}
                </Box>

                <Box sx={{ display: 'flex', mb: 2 }}>
                    <Box sx={{ flex: 1, mr: 2 }}>
                        <Typography variant="body1" sx={{ mb: 1 }}>
                            Expiration date
                        </Typography>
                        {editorActive ? (
                            <Box
                                sx={{
                                    borderRadius: '8px',
                                    padding: '16px',
                                    backgroundColor: '#f9f9f9',
                                    height: '55px',
                                    maxWidth: '300px',
                                }}
                            >
                                <CardExpiryElement />
                            </Box>
                        ) : (
                            <Typography variant="body1" sx={{ fontSize: '16px', color: '#32325d' }}>
                                {card ? `${card.exp_month}/${card.exp_year}` : '**/**'}
                            </Typography>
                        )}
                    </Box>
                    <Box sx={{ flex: 1 }}>
                        <Typography variant="body1" sx={{ mb: 1 }}>
                            CVC
                        </Typography>
                        {editorActive ? (
                            <Box
                                sx={{
                                    borderRadius: '8px',
                                    padding: '16px',
                                    backgroundColor: '#f9f9f9',
                                    height: '55px',
                                    maxWidth: '300px',
                                }}
                            >
                                <CardCvcElement />
                            </Box>
                        ) : (
                            <Typography variant="body1" sx={{ fontSize: '16px', color: '#32325d' }}>
                                ***
                            </Typography>
                        )}
                    </Box>
                </Box>

                <Box>
                    <Typography variant="body1" sx={{ mb: 1 }}>
                        Billing address
                    </Typography>
                    {editorActive ? (
                        <>
                            <TextField
                                label="Address line 1"
                                variant="outlined"
                                fullWidth
                                value={billingAddress.line1}
                                onChange={(e) => setBillingAddress({ ...billingAddress, line1: e.target.value })}
                                sx={stripeElementStyle}
                            />
                            <TextField
                                label="Address line 2"
                                variant="outlined"
                                fullWidth
                                value={billingAddress.line2}
                                onChange={(e) => setBillingAddress({ ...billingAddress, line2: e.target.value })}
                                sx={stripeElementStyle}
                            />
                            <TextField
                                label="City"
                                variant="outlined"
                                fullWidth
                                value={billingAddress.city}
                                onChange={(e) => setBillingAddress({ ...billingAddress, city: e.target.value })}
                                sx={stripeElementStyle}
                            />
                            <TextField
                                label="Postal code"
                                variant="outlined"
                                fullWidth
                                value={billingAddress.postal_code}
                                onChange={(e) => setBillingAddress({ ...billingAddress, postal_code: e.target.value })}
                                sx={stripeElementStyle}
                            />
                        </>
                    ) : (
                        <Typography variant="body1" sx={{ fontSize: '16px', color: '#32325d' }}>
                            {billing_details?.address?.line1 ? `${billing_details.address.line1}, ` : '********** '}
                            {billing_details?.address?.line2 ? `${billing_details.address.line2}, ` : ''}
                            {billing_details?.address?.city ? `${billing_details.address.city}, ` : '***** '}
                            {billing_details?.address?.postal_code
                                ? `${billing_details.address.postal_code} `
                                : '***** '}
                        </Typography>
                    )}
                </Box>
            </Box>
        );
    };

    return (
        <Box className="settings-container">
            <Box
                className="settings-block"
                sx={{
                    backgroundColor: 'white',
                    borderRadius: 4,
                    padding: '16px 24px 16px 24px',
                    my: 3,
                }}
            >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
                    <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                        Billing details
                    </Typography>
                    {!editorActiveDefault && (
                        <IconButton onClick={() => setEditorActive(!editorActive)} sx={{ p: 0 }}>
                            {editorActive ? <img src={CloseIcon} alt="Close" /> : <img src={EditIcon} alt="Edit" />}
                        </IconButton>
                    )}
                    <Divider
                        sx={{
                            width: 'calc(100% + 48px)',
                            position: 'absolute',
                            bottom: 0,
                            left: '-24px',
                            backgroundColor: 'rgba(229, 229, 229, 1)',
                            display: editorActive ? 'none' : 'block',
                        }}
                    />
                </Box>

                {paymentRequest && (
        <Box sx={{ mt: 3 }}>
          <Typography variant="h6">Pay with Google Pay</Typography>
          <Box sx={{ maxWidth: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '200px', height: '60px' }}>
            <PaymentRequestButtonElement
              options={{ paymentRequest }}
              onClick={handlePaymentRequest}
              style={{
                paymentRequestButton: {
                  theme: 'dark', 
                  height: '60px',
                  width: '200px',
                },
              }}
            />
          </Box>
        </Box>
      )}

                {renderStripeField()}

                {editorActive && !editorActiveDefault && (
                    <Box display="flex" flexDirection="column" gap={2}>
                        <PinkGradientButton className="black-btn" onClick={handleSubmitPayment} sx={{ my: 3 }}>
                            Save changes
                        </PinkGradientButton>
                    </Box>
                )}
            </Box>

            {location.pathname.includes('/checkout') && showPromo && (
                <Box
                    className="settings-block"
                    sx={{
                        backgroundColor: 'transparent',
                        borderRadius: 4,
                        padding: '16px 24px 16px 24px',
                        my: 3,
                    }}
                >
                    <Typography variant="h5" sx={{ fontWeight: 600 }}>
                        Promo code
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', mt: 1, mx: -3 }}>
                        <TextField
                            placeholder="Enter code"
                            variant="outlined"
                            fullWidth
                            value={promoCode}
                            onChange={(e) => setPromoCode(e.target.value)}
                            InputProps={{
                                sx: {
                                    paddingLeft: '16px',
                                },
                            }}
                        />

                        <PinkGradientButton onClick={handleValidatePromoCode} sx={{ marginLeft: 2 }}>
                            Apply
                        </PinkGradientButton>
                    </Box>
                    {promoMessage && (
                        <div style={{ marginTop: '10px', display: 'flex', alignItems: 'center' }}>
                            {promoStatus === 'success' ? (
                                <CheckCircleIcon style={{ color: 'green', marginRight: '8px' }} />
                            ) : (
                                <ErrorIcon style={{ color: 'red', marginRight: '8px' }} />
                            )}
                            <span style={{ color: promoStatus === 'success' ? 'green' : 'red' }}>
                                {promoMessage} {promoDiscount && `Enjoy a ${promoDiscount}% discount on your plan!`}
                            </span>
                        </div>
                    )}
                </Box>
            )}

            {editorActive && editorActiveDefault && (
                <Box sx={{ mt: 3, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Box onClick={handleBack} sx={{ display: 'flex', alignItems: 'center', width: 120 }}>
                        <KeyboardBackspaceIcon />
                        <Button
                            sx={{
                                color: '#000453',
                                textDecoration: 'underline',
                                textTransform: 'none',
                                fontWeight: 600,
                            }}
                        >
                            Go back
                        </Button>
                    </Box>
                    <PinkGradientButton onClick={() => setDialogOpen(true)}>Continue</PinkGradientButton>
                </Box>
            )}

            <ConfirmationDialog
                open={dialogOpen}
                handleClose={() => {
                    setDialogOpen(false);
                }}
                onConfirm={handleConfirmSubscription}
                title="Great choice! You're just one click away from more features!"
                message="Please confirm that you want to proceed with your upgrade. Your upgrade will be instant, and
      this will involve a pre-authorised payment."
                confirmText="Yes, upgrade"
                cancelText="No, cancel"
            />
        </Box>
    );
};

const SettingsBillingWrapper = (props) => {
    return (
        <Elements stripe={stripePromise}>
            <SettingsBilling {...props} />
        </Elements>
    );
};

export default SettingsBillingWrapper;
