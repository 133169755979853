import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, useMediaQuery, FormControlLabel } from '@mui/material';
import BulletList from './bullet-list';
import NumberList from './number-list';
import Model3DViewer from './model3DViewer';
import Callout from './callout';
import StoreContext from '../../../react-core-lib/components/app/store-context';
import HandoutImageManager from './handout-image-manager';
import HandoutImageLoader from './handout-image-loader';
import IconStrip from './icon-strip';
import Copyright from './copyright';
import { GlobalStyles } from '@mui/system';
import { useTheme } from '@mui/material/styles';
import AntSwitch from '../../UIControls/AntSwitch';
import CTAUpgradeDialog from '../../UIControls/CTAUpgradeDialog';
import ctaUpgradeProfessionalPaywall from '../../../assets/banners/ctaUpgradeProfessionalPaywall.png';

function DisplayHandout({
    handoutData,
    showLabels,
    setShowLabels,
    show3DModel,
    showMainIllustration,
    showTutorialVideo,
}) {
    const { authStore, imageStore, userDataStore } = useContext(StoreContext);
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isSmallScreen = useMediaQuery('(max-width:750px)');
    const appBarHeight = theme.spacing(8);
    const locale = 'en-UK';
    const localeData = handoutData.contentData[locale];
    const is3DOnly = localeData.length == 2;
    const [upgradeCTAOpen, setUpgradeCTAOpen] = useState(false);
    // console.warn('display-handout data ', toJS(handoutData));
    console.warn('display-handout is3DOnly? ', is3DOnly);

    useEffect(() => {
        if (is3DOnly === true && userDataStore?.subscriptionData?.accountType === 'Student') {
            setUpgradeCTAOpen(true);
        }
    }, [is3DOnly]);

    if (!authStore.idToken) {
        throw new Error(`No idToken found`);
    }

    if (!localeData) {
        throw new Error(`No data found for the specified locale: ${locale}`);
    }

    return (
        <>
            <HandoutImageManager handoutData={handoutData}>
                <GlobalStyles
                    styles={{
                        '@media print': {
                            '#handout-content': {
                                paddingBottom: '100px',
                            },
                        },
                    }}
                />
                <Box id="handout-content" sx={{ my: 2, pb: isMobile ? appBarHeight : '0px', position: 'relative' }}>
                    {localeData.map((section, index) => generateSection(section, index))}
                    <Copyright />
                </Box>
            </HandoutImageManager>

            <CTAUpgradeDialog
                open={upgradeCTAOpen}
                handleClose={() => {
                    setUpgradeCTAOpen(false);
                }}
                onConfirm={() => navigate('/settings/manage-subscription')}
                title="Unlock unlimited sharing and more with just one click!"
                message="You've reached a feature that's beyond your current plan. Upgrade today to enjoy full access!"
                buttonText="Upgrade plan"
                benefits={[
                    'Unlimited digital sharing of all handouts',
                    'Unlimited digital sharing of all anatomy diagrams',
                    'Personalise with your clinic details ',
                ]}
                imageUrl={ctaUpgradeProfessionalPaywall}
                isLocked={true}
            />
        </>
    );

    function generateSection(jsonContent, index) {
        const sectionName = jsonContent.sectionName;
        const isPrintPageBreak = sectionName === 'printPageBreak';
        let blocks = sectionName === 'diagram' ? [jsonContent.blocks[showLabels ? 0 : 1]] : jsonContent.blocks;

        // Filter blocks based on toggle states
        blocks = blocks.filter((block) => {
            if (userDataStore?.subscriptionData?.accountType === 'Student' && block.type === '3dModel') {
                return false;
            }
            if (block.type === '3dModel' && !show3DModel) return false;
            if (block.type === 'illustration' && !showMainIllustration) return false;
            if (block.type === 'tutorialVideo' && !showTutorialVideo) return false;
            return true;
        });

        if (blocks.length === 0) {
            return null; // Skip rendering this section if no blocks remain
        }

        return (
            <React.Fragment key={index}>
                <Box
                    component="section"
                    className={`${sectionName}${isPrintPageBreak ? ' printPageBreak' : ''}`}
                    sx={{
                        my: 4,
                        '@media print': {
                            pageBreakBefore: isPrintPageBreak ? 'auto' : 'avoid',
                            position: 'relative',
                            zIndex: 1,
                        },
                    }}
                >
                    {sectionName === 'diagram' && blocks && blocks[0] && (
                        <FormControlLabel
                            control={<AntSwitch checked={showLabels} onChange={() => setShowLabels((prev) => !prev)} />}
                            label="Labels"
                            labelPlacement="start"
                            sx={{
                                position: 'absolute',
                                top: isSmallScreen ? '-24px' : '16px',
                                right: 0,
                                '& .MuiFormControlLabel-label': { marginRight: '8px' },
                            }}
                        />
                    )}
                    {blocks.map((block, index) => (
                        <React.Fragment key={index}>{blockToComponentMapping(block, sectionName)}</React.Fragment>
                    ))}
                </Box>
                {isPrintPageBreak && <div></div>}
            </React.Fragment>
        );
    }

    // top level component which checks CMS block type then generates MUI/custom component for different blocks
    function blockToComponentMapping(block, sectionName) {
        if (!block || !block.type) {
            console.error(`Block is missing type or is undefined`, block);
            return null;
        }
        switch (block.type) {
            case 'documentTitle':
                return (
                    <Typography component="h1" key={block.value} variant="documentTitle">
                        {block.value}
                    </Typography>
                );
            case 'sectionTitle':
                return (
                    <Typography component="h2" key={block.value} variant="sectionTitle" sx={{ my: 2 }}>
                        {block.value}
                    </Typography>
                );
            case 'sectionSubtitle':
                return (
                    <Typography component="h3" key={block.value} variant="sectionSubtitle" sx={{ my: 2 }}>
                        {block.value}
                    </Typography>
                );
            case 'calloutSmall':
                return <Callout key={block.value}>{block.value}</Callout>;
            case 'calloutMedium':
                return <Callout key={block.value}>{block.value}</Callout>;
            case 'paragraph':
                return (
                    <Typography
                        key={block.value}
                        sx={{
                            position: 'relative',
                            zIndex: 1,
                            pb: 1,
                            '@media print': {
                                pageBreakInside: 'auto',
                                pageBreakBefore: 'auto',
                                display: 'inline',
                            },
                        }}
                    >
                        {block.value}
                    </Typography>
                );
            case 'list':
                return <BulletList items={block.values} />;
            case 'numberList':
                return <NumberList items={block.values} />;
            case 'iconStrip':
                return (
                    <IconStrip
                        iconDetails={block.iconDetails}
                        imageStore={imageStore}
                        sx={{
                            position: 'relative',
                            zIndex: 1,
                            '@media print': {
                                pageBreakInside: 'avoid',
                                pageBreakBefore: 'auto',
                                pb: 1,
                                display: 'flex',
                            },
                        }}
                    />
                );
            case 'illustration':
                return <HandoutImageLoader src={block.src} alt={block.alt} imageStore={imageStore} />;
            case '3dModel': // 3D Model thing!
                return (
                    <Model3DViewer uid={block.uid} alt={block.alt} author={block.author} authorWeb={block.authorWeb} />
                );
            case 'tutorialVideo':
                return (
                    <Box key={block.src} sx={{ my: 2 }}>
                        <video width="100%" controls>
                            <source src={block.src} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </Box>
                );
            case 'printPageBreak':
                return (
                    <>
                        <div className="printPageBreak"></div>
                        <p></p>
                    </>
                );
            default:
                return null;
        }
    }
}

export default DisplayHandout;
